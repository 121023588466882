
export default function Contacts() {



    return (
        <div>
            {/* Contact */}
            < div id="contact" className="py-12 sm:py-12 scroll-mt-24" >
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl space-y-16 divide-y divide-gray-100 lg:mx-0 lg:max-w-none">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
                            <div>
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Contact and Socials</h2>
                                <p className="mt-4 leading-7 text-gray-600">
                                    Follow our story and support our journey as we are Transforming Youth Behavior
                                </p>
                            </div>
                            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
                                <div className="rounded-2xl bg-gray-50 p-10">
                                    <h3 className="text-base font-semibold leading-7 text-gray-900">Candice Tolbert, Executive Director</h3>
                                    <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-600">

                                        <div className="mt-1">
                                            <dt className="sr-only">Phone number</dt>
                                            <dd>+1 (513) 549-1516</dd>
                                        </div>
                                        <div>
                                            <dt className="sr-only">Email</dt>
                                            <dd>
                                                <a className="font-semibold text-blue-600" href="mailto:info@SuperSeedsCincy.org">
                                                    info@SuperSeedsCincy.org
                                                </a>
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                                <div className="rounded-2xl bg-gray-50 p-10">
                                    <h3 className="text-base font-semibold leading-7 text-gray-900">Mail</h3>
                                    <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-600">
                                        <div>
                                            <dt className="sr-only">Address</dt>
                                            <dd>
                                                <a className="font-semibold">
                                                    SuperSeeds<br />
                                                    10142 Springfield Pike,<br />
                                                    Cincinnati, OH 45215
                                                </a>
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    );
}
