
import { Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import NewsAndMedia from './components/NewsAndMedia';
import Partners from './components/Partners';
import WhatWeDo from './components/WhatWeDo';
import Faqs from './components/Faqs';
import WaysToHelp from './components/WaysToHelp';
import MeetTheFounder from './components/MeetTheFounder';
import BoardOfDirectors from './components/BoardOfDirectors';
import OurData from './components/OurData';
import IntakeForm from './components/IntakeForm';
import Volunteer from './components/Volunteer';
export default function App() {

  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/media" element={< NewsAndMedia />} />
        <Route path="/whatwedo" element={< WhatWeDo />} />
        <Route path="/volunteer" element={< Volunteer />} />
        <Route path="/faqs" element={< Faqs />} />
        <Route path="/waystohelp" element={< WaysToHelp />} />
        <Route path="/founder" element={< MeetTheFounder />} />
        <Route path="/board" element={<BoardOfDirectors />} />
        <Route path="/data" element={<OurData />} />
        <Route path="/intake" element={<IntakeForm />} />
        <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>
    </div>
  )
}
