import Footer from "./Footer";
import NavBar from "./NavBar";
import NavToHome from "./NavToHome";
import { CheckCircleIcon } from '@heroicons/react/20/solid'

export default function WaysToHelp() {

    return (
        <div>
            {/* Header */}
            <NavBar />
            <div className="bg-white px-6 py-12 lg:px-8">
                <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                    <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"> Ways To Help</h1>
                    <div className="mt-10 max-w-2xl">
                        <p>

                        </p>
                        <ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600">
                            <li className="flex gap-x-3">
                                <p>1)</p>
                                <span>
                                    <strong className="font-semibold text-gray-900">THIS COSTS YOU NOTHING OUT OF POCKET: Simple &amp; Easy!!!</strong>
                                    <br />
                                    Connect your Kroger Card to the <a className="text-blue-600 underline hover:text-blue-800" href="https://www.kroger.com">Kroger Community Rewards Program</a> and select
                                    SuperSeeds Cincinnati, Non-Profit Organization Number is <strong>UV679</strong>, as your charity of choice. SuperSeeds Cincinnati will automatically receive a small
                                    percentage of every transaction you make at Kroger when you use your Kroger Rewards Card.
                                </span>
                            </li>
                            <li className="flex gap-x-3">
                                <p>2)</p>
                                <span>
                                    <strong className="font-semibold text-gray-900">THIS COSTS YOU LESS THAN $1.00 EACH TIME YOU DONATE: Simple &amp; Easy!!!</strong>
                                    <br />
                                    Connect your Walmart ONLINE account (Walmart.com OR Walmart App) to the Walmart
                                    Spark Good Program <a className="text-blue-600 underline hover:text-blue-800" href="https://www.walmart.com/sparkgood">HERE</a> and SuperSeeds will automatically receive your
                                    ‘change’ as you round up your purchase to the nearest dollar.
                                </span>
                            </li>
                            <li className="flex gap-x-3">
                                <p>3)</p>
                                <span>
                                    <strong className="font-semibold text-gray-900">DONATE any Amount <a className="text-blue-600 underline hover:text-blue-800" href="https://superseedscincy.org/#donate">HERE</a>: Simple &amp; Easy!!!!</strong>
                                    <br />
                                    We appreciate your generosity and support for our Cincinnati youth. Visit our Charity
                                    Navigator Review <a className="text-blue-600 underline hover:text-blue-800" href="https://www.charitynavigator.org/ein/461952948">HERE</a> and our GuideStar Review <a className="text-blue-600 underline hover:text-blue-800" href="https://www.guidestar.org/profile/46-1952948">HERE</a>
                                </span>
                            </li>
                            <li className="flex gap-x-3">
                                <p>4)</p>
                                <span>
                                    <strong className="font-semibold text-gray-900">VOLUNTEER Your Time: Make a Direct Impact!</strong>
                                    <br />
                                    Join our team of dedicated volunteers and help make a difference in the lives of Cincinnati youth. 
                                    Learn more about our volunteering programs <a className="text-blue-600 underline hover:text-blue-800" href="/volunteer">HERE</a>.
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    );

}