import Footer from "./Footer";
import NavBar from "./NavBar";

export default function IntakeForm() {
    return (
        <div>
            {/* Header */}
            <NavBar />
            <div className="bg-white">
                <div className="mx-auto max-w-4xl">
                    <h1 className="mt-2 mb-4 text-2xl font-semibold tracking-tight text-gray-900">Camp Registration Form</h1>
                    <p className="mt-5 mb-5 text-justify text-lg leading-8 text-gray-900">
                        Thank you for your interest in registering your youth (between the ages of 13-17) for the 2-Day Transformation Camp.
                        Please complete the form below and select your camp date.  Once we receive your registration information, we will confirm your child's registration.
                        <hr/>
                    </p>
                    
                    <form class="w-full max-w-lg" method='post' action='https://www.claritycrm.com/d1/leadwebform.aspx?tk=9ef0647d04034b4faeb08501609eb5c3' onsubmit='return get_action();'>

                        <table width='570' border='0' cellpadding='2' cellspacing='0'>
                            <tbody>
                                <tr><td><label for='ctl_firstname'>First Name<strong >*</strong> : </label></td><td><input required pattern=".*[^ ].*" placeholder='First Name' type='text' id='ctl_firstname' name='ctl_firstname' /></td></tr>
                                <tr><td ><label for='ctl_lastname'>Last Name<strong >*</strong> : </label></td><td><input required pattern=".*[^ ].*" placeholder='Last Name' type='text' id='ctl_lastname' name='ctl_lastname' /></td></tr>
                                <tr><td ><label for='ctl_middlename'>MiddleName : </label></td><td><input placeholder='MiddleName' type='text' id='ctl_middlename' name='ctl_middlename' /></td></tr>
                                <tr><td ><label for='ctl_city'>City : </label></td><td><input placeholder='City' type='text' id='ctl_city' name='ctl_city' /></td></tr>
                                <tr><td ><label for='ctl_state'>State : </label></td><td><input placeholder='State' type='text' id='ctl_state' name='ctl_state' /></td></tr>
                                <tr><td ><label for='ctl_zipcode'>Zipcode : </label></td><td><input placeholder='Zipcode' type='text' id='ctl_zipcode' name='ctl_zipcode' /></td></tr>
                                <tr><td ><label for='ctl_udf_bd49923b-e1eb-4914-a043-be6024a702a4'>Address : </label></td><td><input placeholder='Address' type='text' id='ctl_udf_bd49923b-e1eb-4914-a043-be6024a702a4' name='ctl_udf_bd49923b-e1eb-4914-a043-be6024a702a4' /></td></tr>
                                <tr><td ><label for='ctl_udf_cbe48903-9dcd-452e-bdce-24b4e73111be'>Gender<strong >*</strong> : </label></td><td><select required pattern=".*[^ ].*" id='ctl_udf_cbe48903-9dcd-452e-bdce-24b4e73111be' name='ctl_udf_cbe48903-9dcd-452e-bdce-24b4e73111be'><option value='Female'>Female</option><option value='Male'>Male</option></select></td></tr>
                                <tr><td ><label for='ctl_udf_5972765f-1397-4f72-838f-08b64a4ec933'>Race<strong >*</strong> : </label></td><td><select required pattern=".*[^ ].*" id='ctl_udf_5972765f-1397-4f72-838f-08b64a4ec933' name='ctl_udf_5972765f-1397-4f72-838f-08b64a4ec933'><option value='Black'>Black</option><option value='White'>White</option><option value='Hispanic'>Hispanic</option><option value='Other (Please list)'>Other (Please list)</option></select></td></tr>
                                <tr><td ><label for='ctl_udf_e5b02443-474b-4469-abdb-4eec765244a7'>Date of Birth<strong >*</strong> : </label></td><td><input required pattern=".*[^ ].*" placeholder='Date of Birth' type='date' id='ctl_udf_e5b02443-474b-4469-abdb-4eec765244a7' name='ctl_udf_e5b02443-474b-4469-abdb-4eec765244a7' /></td></tr>
                                <tr><td ><label for='ctl_udf_7b8aab39-fccf-4ba2-9869-1aa3530cbdc4'>Home Phone # : </label></td><td><input placeholder='Home Phone #' type='text' id='ctl_udf_7b8aab39-fccf-4ba2-9869-1aa3530cbdc4' name='ctl_udf_7b8aab39-fccf-4ba2-9869-1aa3530cbdc4' /></td></tr>
                                <tr><td ><label for='ctl_udf_fced008c-f178-40ce-a32a-5ca42c6ff352'>Cell Phone #<strong >*</strong> : </label></td><td><input required pattern=".*[^ ].*" placeholder='Cell Phone #' type='text' id='ctl_udf_fced008c-f178-40ce-a32a-5ca42c6ff352' name='ctl_udf_fced008c-f178-40ce-a32a-5ca42c6ff352' /></td></tr>
                                <tr><td ><label for='ctl_udf_9a07a172-daf5-4c96-8894-f5e8b9479ebf'>E-mail Address<strong >*</strong> : </label></td><td><input required pattern=".*[^ ].*" placeholder='E-mail Address' type='text' id='ctl_udf_9a07a172-daf5-4c96-8894-f5e8b9479ebf' name='ctl_udf_9a07a172-daf5-4c96-8894-f5e8b9479ebf' /></td></tr>
                                <tr><td ><label for='ctl_udf_47dea441-5fa1-4fce-9e97-6e0355bd3b29'>Parent or Guardian First Name : </label></td><td><input placeholder='Parent or Guardian First Name' type='text' id='ctl_udf_47dea441-5fa1-4fce-9e97-6e0355bd3b29' name='ctl_udf_47dea441-5fa1-4fce-9e97-6e0355bd3b29' /></td></tr>
                                <tr><td ><label for='ctl_udf_cc5df1c0-ef3d-4c91-9bfd-656d52e489f2'>Parent or Guardian Last Name : </label></td><td><input placeholder='Parent or Guardian Last Name' type='text' id='ctl_udf_cc5df1c0-ef3d-4c91-9bfd-656d52e489f2' name='ctl_udf_cc5df1c0-ef3d-4c91-9bfd-656d52e489f2' /></td></tr>
                                <tr><td ><label for='ctl_address1'>Address1 : </label></td><td><input placeholder='Address1' type='text' id='ctl_address1' name='ctl_address1' /></td></tr>
                                <tr><td ><label for='ctl_phone'>Phone : </label></td><td><input placeholder='Phone' type='text' id='ctl_phone' name='ctl_phone' /></td></tr>
                                <tr><td ><label for='ctl_email'>Email<strong >*</strong> : </label></td><td><input required pattern=".*[^ ].*" placeholder='Email' type='text' id='ctl_email' name='ctl_email' /></td></tr>
                                <tr><td ><label for='ctl_udf_bcaab68c-8f8a-42ba-8054-20b10ce96615'>Employment Status : </label></td><td><select id='ctl_udf_bcaab68c-8f8a-42ba-8054-20b10ce96615' name='ctl_udf_bcaab68c-8f8a-42ba-8054-20b10ce96615'><option value='Full-Time'>Full-Time</option><option value='Part-Time'>Part-Time</option></select></td></tr>
                                <tr><td ><label for='ctl_udf_2a6d252e-2a22-454b-9252-4fc221adca9d'>Annual Income : </label></td><td><select id='ctl_udf_2a6d252e-2a22-454b-9252-4fc221adca9d' name='ctl_udf_2a6d252e-2a22-454b-9252-4fc221adca9d'><option value='$23K & Under'>$23K & Under</option><option value='$24K-$50K'>$24K-$50K</option><option value='$51K-$100K'>$51K-$100K</option><option value='$100K+'>$100K+</option></select></td></tr>
                                <tr><td ><label for='ctl_udf_79d685cf-c9fb-4bae-97c5-f59c04e49e8c'>Total # of Dependents in House : </label></td><td><input placeholder='Total # of Dependents in House' type='text' id='ctl_udf_79d685cf-c9fb-4bae-97c5-f59c04e49e8c' name='ctl_udf_79d685cf-c9fb-4bae-97c5-f59c04e49e8c' /></td></tr>
                                <tr><td ><label for='ctl_udf_519c4df7-78b9-4c78-8f3f-48af3a9681c5'>Marital Status : </label></td><td><select id='ctl_udf_519c4df7-78b9-4c78-8f3f-48af3a9681c5' name='ctl_udf_519c4df7-78b9-4c78-8f3f-48af3a9681c5'><option value='Single'>Single</option><option value='Married'>Married</option><option value='Divorced'>Divorced</option></select></td></tr>
                                <tr><td ><label for='ctl_udf_1de9360d-2be3-4bdd-b612-927c6e3503a5'>Interested In Other Programs : </label></td><td><select id='ctl_udf_1de9360d-2be3-4bdd-b612-927c6e3503a5' name='ctl_udf_1de9360d-2be3-4bdd-b612-927c6e3503a5'><option value='Yes'>Yes</option><option value='No'>No</option></select></td></tr>
                                <tr><td ><label for='ctl_udf_230288d7-659c-4590-8e15-f07bf40af105'>If Yes How Often : </label></td><td><select id='ctl_udf_230288d7-659c-4590-8e15-f07bf40af105' name='ctl_udf_230288d7-659c-4590-8e15-f07bf40af105'><option value='Weekly'>Weekly</option><option value='Monthly'>Monthly</option><option value='Annually'>Annually</option></select></td></tr>
                                <tr><td ><label for='ctl_udf_0a2d6f5f-cb51-4725-b4d6-0389d2b7e6a0'>School Name : </label></td><td><input placeholder='School Name' type='text' id='ctl_udf_0a2d6f5f-cb51-4725-b4d6-0389d2b7e6a0' name='ctl_udf_0a2d6f5f-cb51-4725-b4d6-0389d2b7e6a0' /></td></tr>
                                <tr><td ><label for='ctl_udf_e5898c6e-9078-4d59-ac74-55c4fac4a607'>Grade Level : </label></td><td><select id='ctl_udf_e5898c6e-9078-4d59-ac74-55c4fac4a607' name='ctl_udf_e5898c6e-9078-4d59-ac74-55c4fac4a607'><option value='7'>7</option><option value='8'>8</option><option value='9'>9</option><option value='10'>10</option><option value='11'>11</option><option value='12'>12</option></select></td></tr>
                                <tr><td ><label for='ctl_udf_23ca97d7-7ba1-42b7-8378-a45ab14ef9d1'>Current GPA : </label></td><td><input placeholder='Current GPA' type='text' id='ctl_udf_23ca97d7-7ba1-42b7-8378-a45ab14ef9d1' name='ctl_udf_23ca97d7-7ba1-42b7-8378-a45ab14ef9d1' /></td></tr>
                                <tr><td ><label for='ctl_udf_6b2109e0-28ce-46a1-9de8-91725c0a558f'>Has Your Child Repeated A Grade : </label></td><td><select id='ctl_udf_6b2109e0-28ce-46a1-9de8-91725c0a558f' name='ctl_udf_6b2109e0-28ce-46a1-9de8-91725c0a558f'><option value='Yes'>Yes</option><option value='No'>No</option></select></td></tr>
                                <tr><td ><label for='ctl_udf_afed2fdd-a448-4f9d-a8b8-3666d8cfe8c0'>Child school attendance per week : </label></td><td><select id='ctl_udf_afed2fdd-a448-4f9d-a8b8-3666d8cfe8c0' name='ctl_udf_afed2fdd-a448-4f9d-a8b8-3666d8cfe8c0'><option value='1-3 day(s)'>1-3 day(s)</option><option value='4-5 days'>4-5 days</option></select></td></tr>
                                <tr><td ><label for='ctl_udf_cfd769ac-f836-468d-a20b-2f3fb387ceac'>Lunch Status : </label></td><td><select id='ctl_udf_cfd769ac-f836-468d-a20b-2f3fb387ceac' name='ctl_udf_cfd769ac-f836-468d-a20b-2f3fb387ceac'><option value='Free '>Free </option><option value='Reduced'>Reduced</option></select></td></tr>
                                <tr><td ><label for='ctl_udf_9b05f662-39af-4a2d-8800-80ba4435e336'>Average academic grades : </label></td><td><select id='ctl_udf_9b05f662-39af-4a2d-8800-80ba4435e336' name='ctl_udf_9b05f662-39af-4a2d-8800-80ba4435e336'><option value='A'>A</option><option value='B'>B</option><option value='C'>C</option><option value='D or lower'>D or lower</option></select></td></tr>
                                <tr><td ><label for='ctl_udf_f0b37335-ef90-4ba6-8442-7d39227283f4'>Average behavioral referrals : </label></td><td><select id='ctl_udf_f0b37335-ef90-4ba6-8442-7d39227283f4' name='ctl_udf_f0b37335-ef90-4ba6-8442-7d39227283f4'><option value='1-5'>1-5</option><option value='5-10'>5-10</option><option value='10-20'>10-20</option></select></td></tr>
                                <tr><td ><label for='ctl_udf_aa4f89ea-e5b4-4429-b2a9-89d9114da4ad'>Suspended or Expelled : </label></td><td><select id='ctl_udf_aa4f89ea-e5b4-4429-b2a9-89d9114da4ad' name='ctl_udf_aa4f89ea-e5b4-4429-b2a9-89d9114da4ad'><option value='Yes'>Yes</option><option value='No'>No</option></select></td></tr>
                                <tr><td ><label for='ctl_udf_3dcad06f-9a65-4255-bfb1-0576e89a51e5'>Alternative School Placement : </label></td><td><select id='ctl_udf_3dcad06f-9a65-4255-bfb1-0576e89a51e5' name='ctl_udf_3dcad06f-9a65-4255-bfb1-0576e89a51e5'><option value='Yes'>Yes</option><option value='No'>No</option></select></td></tr>
                                <tr><td ><label for='ctl_udf_6319889a-afde-4058-9ca7-e0246ea2fa36'>Juvenile court recommended : </label></td><td><select id='ctl_udf_6319889a-afde-4058-9ca7-e0246ea2fa36' name='ctl_udf_6319889a-afde-4058-9ca7-e0246ea2fa36'><option value='Yes'>Yes</option><option value='No'>No</option></select></td></tr>
                                <tr><td ><label for='ctl_udf_d457e22b-b4f8-4011-83ac-92b0b43d28a2'>Was child convicted of a crime : </label></td><td><select id='ctl_udf_d457e22b-b4f8-4011-83ac-92b0b43d28a2' name='ctl_udf_d457e22b-b4f8-4011-83ac-92b0b43d28a2'><option value='Yes'>Yes</option><option value='No'>No</option></select></td></tr>
                                <tr><td ><label for='ctl_udf_08908ae0-f022-4ea2-9551-3f77b259e444'>Was child tested or treated for : </label></td><td><select id='ctl_udf_08908ae0-f022-4ea2-9551-3f77b259e444' name='ctl_udf_08908ae0-f022-4ea2-9551-3f77b259e444'><option value='Autisim'>Autisim</option><option value='ADHD'>ADHD</option><option value='Depression'>Depression</option><option value='Other'>Other</option></select></td></tr>
                                <tr><td ><label for='ctl_udf_63c468f0-d58f-45bd-bfd1-af59ce0a924d'>How can we better support you : </label></td><td><textarea rows='5' placeholder='How can we better support you' id='ctl_udf_63c468f0-d58f-45bd-bfd1-af59ce0a924d' name='ctl_udf_63c468f0-d58f-45bd-bfd1-af59ce0a924d'></textarea></td></tr>
                                <tr><td ><label for='ctl_udf_b1205d9d-8002-4562-abae-49fdc6dfcd07'>Anything else we should know : </label></td><td><textarea rows='5' placeholder='Anything else we should know' id='ctl_udf_b1205d9d-8002-4562-abae-49fdc6dfcd07' name='ctl_udf_b1205d9d-8002-4562-abae-49fdc6dfcd07'></textarea></td></tr>
                                <tr ><td colspan='2' >
                                    <input type='hidden' id='g-recaptcha-response' name='g-recaptcha-response' />
                                    <input type='hidden' name='action' value='validate_captcha' />
                                    <input type='hidden' id='hdn_cs_submiturl' name='hdn_cs_submiturl' value='https://superseedscincy.org/' />
                                    <input type='hidden' id='hdn_cs_notify' name='hdn_cs_notify' value='false' />
                                    <input type='hidden' id='hdn_cs_templateid' name='hdn_cs_templateid' value='undefined' />
                                    <input type='hidden' id='hdn_cs_id' name='hdn_cs_id' value='32b50bd2-ab40-4ae5-8f9a-a6eb485e3c11' />
                                    <input type='submit' className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-green-200" id='cx_web_submit' value='Submit' />
                                </td></tr>
                            </tbody>
                        </table>
                    </form>

                    <Footer />
                </div>
            </div>
        </div>

    );

}
