import Footer from "./Footer";
import NavBar from "./NavBar";

export default function Volunteer() {
    return (
        <div className="flex flex-col min-h-screen">
            <NavBar />
            <main className="flex-grow container mx-auto px-4 py-8">
                <section className="mb-8">
                    <h1 className="text-3xl font-bold mb-6 pb-2 border-b-2 border-gray-100">Volunteering</h1>
                    <div className="prose max-w-none mb-6">
                        <p>
                            At SuperSeeds, what we do best is to transform youth behavior to disrupt the pipeline to prison.
                            We plant the seeds of <strong>Awareness</strong>, <strong>Hope</strong> and <strong>Courage</strong> so that we can empower youth to
                            choose to make better decisions creating better outcomes and better communities.
                        </p>
                        <p className="">
                            <strong>YOU</strong> can be a part of the solution by volunteering your time, talent or treasure. Below are some
                            of the volunteer opportunities available.
                        </p>
                    </div>
                </section>

                <div className="bg-orange-100 border-l-4 border-blue-500 text-black-900 p-4 mb-8">
                    <ul className="list-disc pl-5 space-y-2">
                        <li className="font-semibold">
                           To get involved, please email us at <a href="mailto:info@SuperSeedsCincy.org" className="underline text-blue-600 hover:text-orange-700">info@SuperSeedsCincy.org</a> or call us at <a href="tel:513-549-1516" className="underline text-blue-600 hover:text-orange-700">513-549-1516</a>
                        </li>
                        <li className="font-semibold">
                            To donate to SuperSeeds, please click <a href="https://superseedscincy.org/#donate" className="text-blue-600 hover:text-orange-700 underline">here</a>
                        </li>
                    </ul>
                </div>
                <section className="mb-8">
                    <h1 className="text-3xl font-bold mb-6 pb-2 border-b-2 border-gray-100">Transformation Camp Volunteer Opportunities</h1>
                    <div className="space-y-6">
                        <VolunteerRole
                            title="Chaperones"
                            description="Looking for individuals who are able to chaperone the youth during day one of the camp's tours to the Hamilton County Justice Center, UC Medical and Cincinnati State. These tours take place on Tuesdays and Wednesdays two times each month and volunteers would need to be available 8am - 3pm."
                            responsibilities={[
                                "Driving the Van",
                                "Going to Get Meals",
                                "Ensuring Youth Stay with the Group and adhere to camp rules",
                                "Monitoring Bathrooms",
                                "Distributing program materials"
                            ]}
                        />

                        <VolunteerRole
                            title="Drivers"
                            description="Looking for individuals to drive our 15-passenger vans to transport the youth to the Hamilton County Justice Center, UC Medical and Cincinnati State as part of day one of the Transformation Camp. These tours take place on two Tuesdays each month and volunteers would need to be available 8am - 3pm."
                        />

                        <VolunteerRole
                            title="Registration"
                            description="Looking for someone with excellent customer service and organizational skills to assist in getting youth registered for the camps through phone calls, texting and emailing parents."
                            responsibilities={[
                                "Track participants on a spreadsheet",
                                "Ordering food",
                                "Order swag bags, content, water",
                                "Confirm inventory of t-shirts and swag bag contents for each participant",
                                "Set up the room",
                                "Print out certificates of completion",
                                "Set up registration to check youth in",
                                "Pick up lunch on day 2"
                            ]}
                        />

                        <VolunteerRole
                            title="Transportation Coordinator"
                            description="Looking for someone to coordinate drivers and extra vehicle if necessary for each camp."
                        />

                        <VolunteerRole
                            title="Follow-Ups"
                            description="Looking for someone with excellent customer service and organization skills to contact parents after tours are completed to conduct a survey. These will be done at intervals – 2 weeks post, 3 months, 6 months and one year post camp attendance."
                        />

                        <VolunteerRole
                            title="Data Entry"
                            description="Looking for a detail oriented individual to organize our recently purchased Data Tracking System. Individual will enter and check Intake Forms into the system."
                        />
                    </div>
                </section>
                <div className="my-16"></div>
                <section className="mb-8">
                    
                    <h1 className="text-3xl font-bold mb-6 pb-2 border-b-2 border-gray-100">Ambassador Program Volunteer Opportunities</h1>
                    <div className="space-y-6">
                        <VolunteerRole
                            title="Volunteer Support Volunteer"
                            description="As a Volunteer Support Volunteer, you will assist in various tasks that help ensure the efficiency and effectiveness of our programs and services. This role is essential for maintaining the smooth operation of our activities and providing necessary support to staff, other volunteers, and program participants."
                        />

                        <VolunteerRole
                            title="Event Planning Assistant"
                            description="Looking for help create opportunities for our ambassadors and manage organizing the events calendar. This will require phone calls to the event coordinator, as well as calling the ambassadors to coordinate availability."
                        />

                        <VolunteerRole
                            title="Event Assistant"
                            description="Looking for individuals who are able to attend events and check in the ambassadors as they show up for duties, chaperone the events and pick up supplies as needed."
                        />

                        <VolunteerRole
                            title="Drivers"
                            description="Looking for individuals to drive our 15-passenger vans or their own personal vehicle to transport the youth to and from events."
                        />

                        <div className="bg-white shadow-md p-6 rounded-lg">
                            <h3 className="text-xl font-semibold mb-2">Requirements</h3>
                            <ul className="list-disc list-inside">
                                <li>Background and safety check</li>
                                <li>Good communication skills</li>
                                <li>Enjoy working with youth</li>
                            </ul>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    )
}

function VolunteerRole({ title, description, responsibilities }) {
    return (
        <div className="relative bg-white shadow-md rounded-lg p-6 mb-6 overflow-hidden">
            <div className="absolute left-0 top-0 bottom-0 w-2 bg-gradient-to-b from-orange-500 to-blue-500"></div>
            <div className="relative">
                <h3 className="text-xl font-semibold mb-2">{title}</h3>
                <p className="mb-4">{description}</p>
                {responsibilities && (
                    <div>
                        <h4 className="font-semibold mb-2">Responsibilities may include:</h4>
                        <ul className="list-disc list-inside">
                            {responsibilities.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    )
}
