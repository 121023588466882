import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

const navigation = [
  { name: 'Home', href: '/#home' },
  { name: 'Mission', href: '/#mission' },
  { name: 'Donate', href: '/#donate' },
  { name: 'About Us', href: '/#team' },
  { name: 'Contact', href: '/#contact' },
  { name: 'Our Data', href: '/data' },
  { name: 'Board of Directors', href: '/board' },
]

const navSecondRow = [
  { name: 'What We Do', href: '/whatwedo' },
  { name: 'Volunteer', href: '/volunteer' },
  { name: 'Ways To Help', href: '/waystohelp' },
  { name: 'Collaborators', href: '/partners' },
  { name: 'Media', href: '/media' },
  { name: 'Tour Dates', href: './assets/files/SuperSeeds2024TourDates.pdf', newTab : true },
  { name: 'Camp Registration Form', href: '/intake' },
  { name: 'FAQs', href: '/faqs' },
]


export default function NavBar() {

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  return (
    <header className="sticky inset-x-0 top-0 z-50 bg-gradient-to-r from-orange-200 via-teal-200 to-blue-300">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-2 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">SuperSeeds</span>
            <img
              className="h-13 w-60"
              src="./assets/new-ss-logos/SuperSeeds-Official.png"
              alt="SuperSeeds Logo"
            />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:flex-col">

          <div className="hidden m-auto lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} target = {item.newTab? "_blank" : "_self"} className="text-sm font-semibold leading-6 text-gray-900 hover:bg-white rounded-md pt-2 pb-2 pl-1 pr-1 ">
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navSecondRow.map((item) => (
              <a key={item.name} href={item.href}  target = {item.newTab? "_blank" : "_self"} className="text-sm font-semibold leading-6 text-gray-900 hover:bg-white rounded-md pt-2 pb-2 pl-1 pr-1 ">
                {item.name}
              </a>
            ))}
          </div>
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">SuperSeeds</span>
              <img
                className="h-10 w-auto"
                src="./assets/new-ss-logos/SuperSeeds-Official.png"
                alt=""
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    onClick={() => setMobileMenuOpen(false)}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-lime-50"
                  >
                    {item.name}
                  </a>
                ))}

                {navSecondRow.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    onClick={() => setMobileMenuOpen(false)}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-lime-50"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
