import Footer from "./Footer";
import NavBar from "./NavBar";
import NavToHome from "./NavToHome";
import { CheckCircleIcon } from '@heroicons/react/20/solid'
export default function WhatWeDo() {

    return (
        <div>
            {/* Header */}
            <NavBar />

            <div className="bg-white px-6 py-12 lg:px-8">
                <div className="mx-auto max-w-3xl text-justify text-lg leading-8 text-gray-900">
                    <p className="text-base font-semibold leading-7 text-green-900">What We Do</p>
                    <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Programs &amp; Services</h1>
                    <p className="mt-6 text-l leading-8">
                        SuperSeeds provides a 2-Day Transformation Camp for youth between the ages of 13-17.   The camp addresses youth behavior and violence
                        utilizing a Restorative and Transformative approach.  Youth take a tour to explore the real-life consequences of behavior and how
                        behaviors feed systems.
                    </p>
                    <div className="mt-10 max-w-2xl">
                        <p>
                            The camp focuses on providing youth with:
                        </p>
                        <ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600">
                            <li className="flex gap-x-3">
                                <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-green-600" aria-hidden="true" />
                                <span>
                                    <strong className="font-semibold text-gray-900">Awareness -</strong> Helping youth understand how behaviors feed systems and to make
                                    them aware of the real-life consequences of behavior. (Youth visit the Hamilton County Justice Center to explore career opportunities
                                    and to hear from the employee and the inmate & UC Medical to learn about the effects of gun violence)
                                </span>
                            </li>
                            <li className="flex gap-x-3">
                                <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-green-600" aria-hidden="true" />
                                <span>
                                    <strong className="font-semibold text-gray-900">Hope -</strong> Helping youth understand that they still have value and have very bright
                                    futures ahead of them. Many of the youth who enter into our program are beaten down by their circumstances feeling guilt, shame, and less than.
                                    Our goal is for them to lift their head, acknowledge what they did wrong, and evaluate how they could have changed the outcome.
                                    (Youth visit Cincinnati State Technical & Community College to help them understand the importance of being seated in the classroom and doing the work)
                                </span>
                            </li>
                            <li className="flex gap-x-3">
                                <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-green-600" aria-hidden="true" />
                                <span>
                                    <strong className="font-semibold text-gray-900">Courage -</strong>We hope that by making them aware and providing them with hope, they will have the
                                    courage to do something different.
                                </span>
                            </li>
                        </ul>
                        <br />
                        <p>
                            The camp is designed to explore career opportunities and provide youth with various paths.  The Transformation journey starts with the camp as youth are Restored, Reformed and Redirected.
                        </p>

                        <h2 className="mt-16 mb-4 text-2xl font-bold tracking-tight text-gray-900">2-Day Transformation Camps</h2>
                        <img src="./assets/TourLayout.png" className="w-full" />
                        <p className="mt-6">
                            Through the 2-Day Transformation Camps, lives are reformed, restored and redirected. The youth know
                            they are in a safe space; therefore, they open up and share what is happening in their homes and in
                            their schools. The need is vast, and youth are seeking to be heard and to know that their voice and their
                            existence matter. Often, we have youth who want to return to the Camps and share their experiences
                            with the next group. They want to become future leaders.
                        </p>
                        <p className="mt-6">
                            Due to the exemplary success rate of youth attending SuperSeeds’ 2-Day Transformation Camps, the
                            nonprofit is positioned to serve as a springboard for additional local resources, especially for those
                            youth who are turning 18. The future is bright for those we serve. They begin to think differently, make
                            better choices, and accept what is possible. When you change the way you see things, the things you
                            see will change.
                        </p>
                        <p className="mt-6">
                            The 2-Day Transformation Camps are offered on Tuesdays &amp; Wednesdays from 7:30am to 3:00pm.
                            Transportation, meals, snacks, a T-shirt &amp; supplies are included in the registration fee. Fees are $170
                            per youth to attend the 2-Day Camp. Youth report to 10142 Springfield Pike, Building B, Cincinnati,
                            Ohio, 45215, and are transported by bus to the tour sites.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    );

}