import Footer from "./Footer"
import NavBar from "./NavBar"
import NavToHome from "./NavToHome"

const partner = [
    {
        name: 'Hamilton County Sheriff’s Department',
        imageUrl:'./assets/partners/HamiltonCountySheriff.png',
    },
    {
        name: 'University of Cincinnati Medical Center',
        imageUrl:'./assets/partners/UCH.png',
    },
    {
        name: 'Cincinnati State',
        imageUrl:'./assets/partners/cstate-logo.png',
    },
    {
        name: "US District Attorney's Offices",
        imageUrl:'./assets/partners/us-district.jpg',
    },
    {
        name: 'Cincy Bros Computer Pros LLC',
        imageUrl:'./assets/collabs/cbcp.png',
    },

]

const collaborators = [
    {
        name: 'BUILD YOUR OWN DREAMS (BYOD)',
        imageUrl:'./assets/collabs/byod.png',
        website:'https://www.buildyourowndreams.org/about-1'
    },
    {
        name: 'Cincinnati Golden Gloves for Youth',
        imageUrl:'./assets/collabs/cgg.png',
        website:'https://cincinnatiboxing.org/'
    },
    {
        name: 'I Dream Academy',
        imageUrl:'./assets/collabs/ida.webp',
        website:'https://idreamacademy-cincinnati.org'
    },
    {
        name: 'Ladies of Leadership',
        imageUrl:'./assets/collabs/lol.png',
        website:'https://ladiesofleadership-oh.org/'
    },
    {
        name: 'No Excuses College Tours &amp; Programs',
        imageUrl:'./assets/collabs/placeholder.png',
        website:'https://www.nexcollegetours.org/'
    },
    {
        name: 'Men Involved',
        imageUrl:'./assets/collabs/placeholder.png',
        website:''
    },
]

const school = [
    {
        name: 'Mt. Healthy High School',
        imageUrl:'./assets/partners/MHHS-1.jpg',
    },
    {
        name: 'Lockland High School',
        imageUrl:'./assets/partners/locklandhs.jpg',
    },
    {
        name: 'Aiken High School',
        imageUrl:'./assets/partners/aiken-logo.gif',
    },
    {
        name: 'Carpe Diem High School',
        imageUrl:'./assets/partners/carpediem.png',
    },
    {
        name: 'Hughes High School',
        imageUrl:'./assets/partners/hughes.gif',
    },

]
export default function Partners() {
    return (
        <div className="bg-white">
            {/* Header */}
            <NavBar />
        
            {/* Main content */}
            <main className="isolate">
                <div className="py-4">
                    <div className="mx-auto max-w-7xl px-6 text-center lg:px-8">
                        <div className="mx-auto max-w-2xl">
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl underline">Our Partners</h2>
                           
                        </div>
                        <ul
                            role="list"
                            className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3"
                        >
                            {partner.map((p) => (
                                <li key={p.name}>
                                    <img className="mx-auto h-56 w-56 rounded-2xl object-scale-down" src={p.imageUrl} alt="" />
                                    <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-gray-900">{p.name}</h3>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="mx-auto max-w-7xl px-6 text-center lg:px-8">
                        <div className="mx-auto max-w-2xl">
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl underline">Our Collaborators</h2>
                           
                        </div>
                        <ul
                            role="list"
                            className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3"
                        >
                            {collaborators.map((p) => (
                                <li key={p.name}>
                                    <img className="mx-auto h-56 w-56 rounded-2xl object-scale-down" src={p.imageUrl} alt="" />
                                    <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href ={p.website} >{p.name}</a>
                                </li>
                            ))}
                        </ul>
                    </div>


                    <div className="mx-auto max-w-7xl px-6 py-14 text-center lg:px-8">
                        <div className="mx-auto max-w-2xl">
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl underline">Former Participating Schools (Pre-Covid)</h2>
                        </div>
                        <ul
                            role="list"
                            className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3"
                        >
                            {school.map((p) => (
                                <li key={p.name}>
                                    <img className="mx-auto h-56 w-56 rounded-2xl object-scale-down" src={p.imageUrl} alt="" />
                                    <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-gray-900">{p.name}</h3>
                                </li>
                            ))}
                        </ul>
                    </div>

                </div>
            </main>
            <Footer/>
        </div>
    )
}
