import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'

const people = [
    {
        name: 'Candice Tolbert',
        role: 'Executive Director',
        imageUrl: './assets/CandiceTolbert.jpg',
        bio: 'Candice, a mother of 3 boys, had a son suspended for staring. She brings the passion of wanting to develop our youth. For the past 5 years, the Tolbert Family has worked with youth at the entry point of the court system by volunteering at Hillcrest Academy. Candice has helped numerous individuals with felonies find gainful employment through entrepreneurship.',
        twitterUrl: '#',
        linkedinUrl: '#',
    },
    {
        name: 'Peter Billey',
        role: 'Board Member',
        imageUrl: './assets/PeterBilley.jpg',
        bio: 'Peter has been with the Hamilton County Sheriff’s office since 2002.  He has worked in Classifications, Community Affairs, and spent the majority of his time within Corrections as a front line Officer.  He is an active member of the southwest Ohio SAR dive team Task Force One.  He is an Indiana native, a Marine Corps veteran, a 1995 graduate of Lake City Community College (Florida), and a 2002 graduate of Cincinnati Bible College & Seminary.  He has been actively participating with SuperSeeds since the partnership with the SO began.',
        twitterUrl: '#',
        linkedinUrl: '#',
    },
    {
        name: 'Captain Danita Pettis',
        role: 'Board Member',
        imageUrl: './assets/CaptainDanitaPettis.jpg',
        bio: 'Captain Danita Pettis is an accomplished law enforcement professional with over 24 years of experience. She has held various positions within the Cincinnati Police Department, including patrol officer, sergeant, lieutenant, and captain. Captain Pettis is a highly regarded leader, having commanded critical units such as the Planning Unit and the Special Services Section. She holds multiple degrees, including a Bachelor of Arts in Political Science and Spanish, and a Master of Science in Criminal Justice. Captain Pettis is actively involved in her community and is a member of prominent organizations such as the NAACP and NOBLE. She is recognized as the second African American female to achieve the rank of Captain in the history of the Cincinnati Police Department.',
        twitterUrl: '#',
        linkedinUrl: '#',
    },
    {
        name: 'Melissa James',
        role: 'Board Member',
        imageUrl: './assets/MelissaJames.jpg',
        bio: 'Melissa is a transformational speaker, mentor, leader and coach from the corporate space to the community at large. She is a proud alumnus of Kentucky State University where she received a bachelor’s degree in business administration. As a wife and mother of 2 she is passionate about helping and serving others, pouring positivity and hope back into our community. Her mission is to redirect one’s focus to the greatness that lies within. Reminding all that they have the power to rewrite their story no matter what their circumstances or past experiences may have been.',
        twitterUrl: '#',
        linkedinUrl: '#',
    },

]

export default function About() {
    const [open, setOpen] = useState(false)
    return (
        <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-20 px-6 lg:px-8">
            <div className="mx-auto lg:mx-0">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">About Us</h2>
                <p className="mx-auto mt-6 text-justify text-lg leading-8 text-gray-900">
                    SuperSeeds is a non-profit organization serving schools, parents, youth (ages 13-17) and the
                    juvenile court system within The Greater Cincinnati areas of Hamilton and Butler counties.
                    SuperSeeds assists all areas of the educational process (student, parent, and teacher) through
                    collaboration with members of the community and by implementing an intervention program
                    to save our youth from the school-to-prison pipeline.
                    <br />
                    SuperSeeds competencies are in the ability to provide:
                    <br />
                    <ul role="list" className="mt-2 max-w-xl space-y-2 text-gray-600">
                        <li className="flex gap-x-3">
                            <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-green-600" aria-hidden="true" />
                            <span>
                                <strong className="font-semibold text-gray-900">Alternative resources for addressing youth behavior and violence.</strong>
                            </span>
                        </li>
                        <li className="flex gap-x-3">
                            <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-green-600" aria-hidden="true" />
                            <span>
                                <strong className="font-semibold text-gray-900">Evaluation and assessments.</strong>
                            </span>
                        </li>
                        <li className="flex gap-x-3">
                            <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-green-600" aria-hidden="true" />
                            <span>
                                <strong className="font-semibold text-gray-900">Timely, relevant, and accessible programs.</strong>
                            </span>
                        </li>
                        <li className="flex gap-x-3">
                            <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-green-600" aria-hidden="true" />
                            <span>
                                <strong className="font-semibold text-gray-900">Mediation assistance for all parties</strong>
                            </span>
                        </li>
                    </ul>
                    <br />

                    Collectively, a means to build stronger communities.<br />
                    We are a Proud member of <br />

                    <img src="./assets/collabs/leadershipCouncil.png" ></img>
                </p>
            </div>
        </div>
    );
}
