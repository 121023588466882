import Footer from "./Footer";
import NavBar from "./NavBar";
import YouTube from 'react-youtube';

const MediaCard = ({ title, children }) => (
  <div className="bg-white shadow-md rounded-lg overflow-hidden">
    <div className="p-6">
      <h3 className="text-lg font-semibold mb-4">{title}</h3>
      {children}
    </div>
  </div>
);

const NewsArticle = ({ title, url, description, imageSrc }) => (
  <div>
    <a className="text-blue-600 hover:text-blue-800 font-medium" href={url} target="_blank" rel="noopener noreferrer">{title}</a>
    <p className="mt-2 text-sm text-gray-600">{description}</p>
    {imageSrc && <img className="mt-4 w-full h-48 object-cover" src={imageSrc} alt={title} />}
  </div>
);

const VideoContent = ({ videoId }) => (
  <div className="aspect-w-16 aspect-h-9">
    <YouTube
      videoId={videoId}
      opts={{
        width: '100%',
        height: '100%',
        playerVars: { autoplay: 0 },
      }}
    />
  </div>
);

const AudioContent = ({ audioSrc }) => (
  <audio controls className="w-full mt-2">
    <source src={audioSrc} type="audio/mpeg" />
    Your browser does not support this audio.
  </audio>
);

export default function NewsAndMedia() {
  return (
    <div className="bg-white-100 min-h-screen">
      <NavBar />
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-12">News and Media Coverage of Superseeds</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <MediaCard title="The Cincinnati HERALD">
            <NewsArticle 
              title="SuperSeeds tour of Federal Courthouse reinforces positive male lifestyle"
              url="https://thecincinnatiherald.com/2024/01/20/superseeds-cincinnati-youth-development/"
              description="SuperSeeds at Federal Court House with Kenneth Parker, U.S. District Attorney & Chief Judge Marbley"
              imageSrc="./assets/SS-Pics/heraldarticlepic.jpg"
            />
          </MediaCard>

          <MediaCard title="WCPO Cincinnati">
            <NewsArticle 
              title="'You still have worth' | Local organization planting seeds of change one teen at a time"
              url="https://www.wcpo.com/news/local-news/finding-solutions/you-still-have-worth-local-organization-planting-seeds-of-change-one-teen-at-a-time"
              description="SuperSeeds changes outcomes by impacting thought and behavior"
              imageSrc="./assets/SS-Pics/wcpo-superseeds.jpg"
            />
          </MediaCard>

          <MediaCard title="SuperSeeds at Lockland High School">
            <VideoContent videoId="GVtsUvT57rA" />
          </MediaCard>

          <MediaCard title="Jail, court tour teaches teens about good choices">
            <VideoContent videoId="tPQRxnWiM3M" />
          </MediaCard>

          <MediaCard title="Radio One Interview 2/5/19">
            <AudioContent audioSrc="./assets/SuperseedsRadioOne.mp3" />
          </MediaCard>

          {/* Add more MediaCard components here as needed */}
        </div>
      </div>
      <Footer />
    </div>
  );
}