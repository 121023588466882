import Footer from "./Footer";
import NavBar from "./NavBar";
import NavToHome from "./NavToHome";

export default function BoardOfDirectors() {

    const boardMembers = [

        {
            name: 'Candice Tolbert',
            title: 'Executive Director',
            href: "#1"
        },
        {
            name: 'Ron Meyer',
            title: 'Board President',
            href: "#2"
        },
        {
            name: 'Captain Danita Pettis',
            title: 'Board Vice President',
            href: "#3"
        },
        {
            name: 'Janny Tolbert',
            title: 'Board Treasurer',
            href: "#4"
        },
        {
            name: 'Melissa James',
            title: 'Board Secretary',
            href: "#5"
        },
        {
            name: 'Officer Pete Billey',
            title: 'Board Member',
            href: "#"
        },
        {
            name: 'Ron Bryant',
            title: 'Board Member & Marketing Director',
            href: "#"
        },
        {
            name: 'Bryan Dell',
            title: 'Board Member',
            href: "#"
        },
        {
            name: 'Andrea Donegan',
            title: 'Board Member',
            href: "#"
        },
        {
            name: 'Greg Mason',
            title: 'Board Member',
            href: "#"
        },
        {
            name: 'Latoya Oden',
            title: 'Board Member & Program Coordinator',
            href: "#"
        },
        {
            name: 'Major Jackie Reed',
            title: 'Board Member',
            href: "#"
        },
        {
            name: 'James Sutton',
            title: 'Board Member',
            href: "#"
        },
        {
            name: 'Darryl Tolbert',
            title: 'Board Member',
            href: "#"
        },
        {
            name: 'Desmond Tolbert',
            title: 'Board Member',
            href: "#"
        },
        {
            name: 'Dexter Tolbert',
            title: 'Board Member',
            href: "#"
        },

    ]
    return (

        <div>
            {/* Header */}
            <NavBar />

            <div className=" py-5">

                <div className="py-10 mx-auto flex-auto items-center">
                    <div className="flex max-w-6xl mx-auto items-center">
                        <h1 className="mt-2 max-w-2xl text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            Board Members
                        </h1>
                    </div>

                    <div className="text-base max-w-5xl mx-auto pt-3 leading-7 text-gray-900">

                        <ul role="list" className="divide-y divide-gray-100">
                            {boardMembers.map((person) => (
                                <li key={person.name} className="flex justify-between gap-x-6 py-5 hover:bg-gray-50">
                                    <div className="flex min-w-0 gap-x-4">
                                        <div className="min-w-0 flex-auto">
                                            <a className="text-l font-bold leading-7 text-gray-900" href={person.href}>
                                                {person.name}
                                            </a>
                                        </div>
                                    </div>
                                    <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                                        <p className="text-l leading-7 text-gray-900">{person.title}</p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>

                </div>

                <div class="flex items-center p-7">
                    <div class="flex-1 border-t-2 border-gray-200"></div>
                </div>

                <div id="1" className="mx-auto px-6">
                    <div className="mx-auto grid max-w-3xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <div
                            className="absolute right-1/2 left-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
                            aria-hidden="true"
                        >
                            <div
                                className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#0daddc] to-[#f3742a] opacity-60"
                                style={{
                                    clipPath:
                                        'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
                                }}
                            />
                        </div>
                        <div className="">
                            <div className="mx-auto relative overflow-hidden rounded-3xl bg-teal-50 px-6 pb-9 pt-9 shadow-2xl sm:px-12 max-w-md lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
                                <img
                                    className="h-full w-full rounded-2xl object-scale-down bg-teal-50"
                                    src="./assets/CandiceTolbert.jpg"
                                    alt=""
                                />

                            </div>
                        </div>
                        <div>
                            <div className="text-base leading-7 text-gray-700 lg:max-w-2xl sm:pl-6">
                                <h1 className="mt-2 max-w-2xl text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                    Introducing Candice Tolbert, Executive Director of SuperSeeds
                                </h1>
                                <div className="max-w-2xl">
                                    <p className="mt-6 sm:mx-auto">
                                        Upon meeting Candice Tolbert, Executive Director of SuperSeeds, you will immediately sense her
                                        genuine care and compassion for others, along with her personal confidence, drive, and determination.
                                        Born and raised in Cincinnati, Ohio, Candice earned her Associate Degree in Business Management from
                                        Cincinnati State Technical and Community College. Not sure of her exact future, she was certain that
                                        she would impact and serve others. “I am a servant in everything I do.” After working in Human
                                        Resources at Duke Energy for 7 years, Candice started her own business, Assist Financial Group, LLC, in
                                        September of 2003. As the Founder and President, she is proud to offer her clients a full suite of Tax
                                        Preparation, Bookkeeping and Payroll Services. Over the past 2 decades, she has assisted individuals,
                                        small business owners and non-profit entities with all aspects of taxes and financials. Her credential as a
                                        Licensed Tax Preparer includes an AFSP Designation, which she earned and has maintained since 2014.
                                    </p>
                                    <p className="mt-6">
                                        In the Fall of 2012, Candice's son was suspended from a private school for playing a 'staring game' with a
                                        fellow student. As a result of the female student's accusation, her son was immediately suspended for
                                        one day. On January 25, 2013, Candice launched SuperSeeds, a non-profit dedicated to serving youth
                                        between the ages of 13-17 who have been suspended or arrested as they understand that these
                                        circumstances feed the pipeline to prison. “Youth need restorative programs that transform their
                                        thinking versus punitive programs. How does a dismissal from the classroom transform thinking or
                                        modify their behavior?” Candice believes in collaboration and offers a 2-Day Transformation Camp,
                                        which serves about 240 youth per year, which unites the village of individuals, business owners, non-
                                        profit organizations, and corporations with a vested community interest in disrupting the pipeline to
                                        prison. 95% of the youth in the Camp are referred to SuperSeeds from Juvenile Court and participating
                                        schools, 3% are referred by parents, and 2% are referred by other youth who have completed the 2-Day
                                        Camp.
                                    </p>
                                    <p className="mt-6">
                                        “Youth need to understand the real-life consequences of their actions from a restorative and
                                        educational approach. During the 2-days, we transport them by bus to the Hamilton County Justice
                                        Center, UC Medical Center and to a local college or university, such as Cincinnati State Technical &amp;
                                        Community College or the University of Cincinnati, so they can understand the path they are on and the
                                        critical role these entities play in our society.” The transformations are miraculous. In the matter of 2-
                                        days, there is change in attitude, demeanor, and behavior. The long-term results are academic
                                        improvement along with an increase in communication with their parents. Such transformations fuel,
                                        motivate, and inspire Candice. She knows that lives are being positively impacted and progress is being
                                        made. Candice genuinely cares about our youth. She is in their corner and provides them with
                                        confidence and empowerment. In turn, they work to become a better version of themselves one day at
                                        a time, knowing that someone genuinely cares about them.
                                    </p>
                                    <p className="mt-6">
                                        Candice Tolbert is a visionary and loves to educate and counsel others. As a clear and concise
                                        communicator, she quickly earns the respect from those she is dedicated to serving. Combine these
                                        gifts with her strong business acumen and here lies her formula for continued success. Candice is
                                        known for saying, “Know More, (and then) Be Better!”
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex items-center p-7">
                    <div class="flex-1 border-t-2 border-gray-200"></div>
                </div>

                <div className="mx-auto px-6">
                    <div id="2" className="mx-auto grid max-w-3xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <div className="">
                            <div className="mx-auto relative overflow-hidden rounded-3xl bg-teal-50 px-6 pb-9 pt-9 shadow-2xl sm:px-12 max-w-md lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
                                <img
                                    className="h-full w-full rounded-2xl object-scale-down bg-teal-50"
                                    src="./assets/RonMeyer.jpg"
                                    alt=""
                                />

                            </div>
                        </div>
                        <div>
                            <div className="text-base leading-7 text-gray-700 lg:max-w-2xl sm:pl-6">
                                <h1 className="mt-2 max-w-2xl text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                    Introducing Ron Meyer, Board President of SuperSeeds
                                </h1>
                                <div className="max-w-2xl">
                                    <p className="mt-6 sm:mx-auto">
                                        Ron Meyer, Board President of SuperSeeds, is a living, breathing example of the word servant. He
                                        embodies both the desire and ability to provide hope, peace, and encouragement to everyone he
                                        meets. Born and raised in Cincinnati, Ohio, Ron earned his Bachelor's in Electrical Engineering
                                        Technology from The University of Cincinnati—College of Applied Sciences. After a 23-year successful
                                        career in Engineering, Ron became aware of life coaching--while in prayer--so he retired at the age of 47
                                        to follow that calling.
                                    </p>
                                    <p className="mt-6">
                                        In 2012, Ron completed an accredited coaching program to become a Certified Professional Coach
                                        through The Institute of Professional Excellence and Coaching. His Life Coaching business, Coaching The
                                        True You, is rooted in healing and addressing one's core. “One's thoughts, turn into feelings, which
                                        manifest into actions. I am passionate about changing one's own narrative and I am uplifted when I see
                                        individuals think about and experience life differently.” One of Ron's favorite quotes is “Life is the
                                        perfect game. It cannot be won or lost, only experienced.”
                                    </p>
                                    <p className="mt-6">
                                        In the spring of 2023, Ron was invited to a community awareness event, hosted by SuperSeeds. There
                                        he was introduced to Candice Tolbert; Executive Director of SuperSeeds and he was immediately drawn
                                        to the mission of serving youth between the ages of 13-17 who have been suspended or arrested. “I
                                        quickly realized that my coaching experience could benefit SuperSeeds—specifically through my skills in
                                        marketing, messaging, and awareness for the nonprofit. I began conversations with Candice, attended
                                        several Board Meetings and accepted the Board President role in August 2023.” Ron has an undeniable
                                        servant's heart and is fulfilled when he helps people positively impact their life. “When you take care of
                                        your core, then other pieces will begin to fall into place.”</p>
                                    <p className="mt-6">
                                        Through SuperSeeds, Ron has a vision of planting seeds of hope, awareness, and courage to youth. On
                                        the large scale, he is excited about Candice's collaborative vision and mindset to work with other
                                        Cincinnati nonprofits. Due to the exemplary success rate of youth attending SuperSeeds' 2-Day
                                        Transformation Camps, the nonprofit is positioned to serve as a springboard for additional local
                                        resources. “The future is bright for those we serve. They begin to think differently, make better choices,
                                        and accept what is possible. When you change the way you see things, the things you see will change.”
                                    </p>
                                    <p className="mt-6">
                                        Ron Meyer feels both excited and humbled to serve as Board President as the organization continues to
                                        grow and change lives. SuperSeeds is fortunate to have a Man of God, who offers his talents with
                                        authenticity, transparency, and intentionality. “To have a positive impact on others is what motivates
                                        me. It does not get any better than that!”
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex items-center p-7">
                        <div class="flex-1 border-t-2 border-gray-200"></div>
                    </div>

                    <div className="mx-auto grid max-w-3xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <div
                            className="absolute right-1/2 left-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
                            aria-hidden="true"
                        >
                            <div
                                className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#0daddc] to-[#f3742a] opacity-60"
                                style={{
                                    clipPath:
                                        'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
                                }}
                            />
                        </div>
                        <div className="">
                            <div className="mx-auto relative overflow-hidden rounded-3xl bg-teal-50 px-6 pb-9 pt-9 shadow-2xl sm:px-12 max-w-md lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
                                <img
                                    className="h-full w-full rounded-2xl object-scale-down bg-teal-50"
                                    src="./assets/CaptainDanitaPettis.jpg"
                                    alt=""
                                />

                            </div>
                        </div>
                        <div id="3">
                            <div className="text-base leading-7 text-gray-700 lg:max-w-2xl sm:pl-6">
                                <h1 className="mt-2 max-w-2xl text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                    Introducing Captain Danita Pettis, Board Vice President of SuperSeeds
                                </h1>
                                <div className="max-w-2xl">
                                    <p className="mt-6 sm:mx-auto">
                                        Captain Danita Pettis, Board Vice President at SuperSeeds, has dedicated her entire life to public
                                        service. She is methodological, deliberate, and assertive in her decision making, both personally and
                                        professionally. “I am driven, and I believe that anything work having is worth fighting and working for.”
                                        Born in Chicago, Illinois, Danita has resided in Cincinnati for most of her life. “As a child, I was always
                                        fascinated with police shows and detective shows, and I knew that someday I would become either an
                                        attorney or serve in law enforcement.”
                                    </p>
                                    <p className="mt-6">
                                        Danita is a three-time graduate of The University of Cincinnati, earning her Bachelor of Arts in Political
                                        Science, Master’s in Criminal Justice, and Bachelor of Arts in Spanish. Between 1989 and 1999, Danita
                                        served in the United States Army Reserve and received training as a Tactical Communications Operator.
                                        Simultaneous with her Military Service, she worked at Matrix Marketing and then for the Cincinnati
                                        Police Department as an Emergency 911 Operator.
                                    </p>
                                    <p className="mt-6">
                                        In 1999, Danita was accepted into the Cincinnati Police Academy. Throughout her extensive law
                                        enforcement career, she has worked her way through the ranks and several promotions to Officer,
                                        Sergeant, and Lieutenant. Since 2019, she has been the Police Captain and District Two Commander of
                                        the Cincinnati Police Department. “I am passionate about community engagement and as Police
                                        Captain, I spend the majority of my time in Command Level Leadership.” Captain Pettis’
                                        accomplishments also include notoriety as only the second African American woman in the 164-year
                                        history of the CPD to be promoted to the command officer rank of Police Captain, and the third woman
                                        to become President of the Sentinel Police Association since its inception in 1968.
                                    </p>
                                    <p className="mt-6">
                                        When Danita was introduced to Candice Tolbert, Executive Director of SuperSeeds, in the fall of 2022,
                                        she was impressed with the program and level of youth engagement. She expressed an interest in
                                        serving on the Board. “I am a person of action, as opposed to reaction. I felt in alignment with the vision
                                        and mission of SuperSeeds, so I joined the Board in June 2023.” SuperSeeds is focused on exposing
                                        youth to various aspects of life. The youth engagement begins with a safe space where they can talk
                                        with adults. During the 2-Day Transformation Camp, the youth ages 13-17, receive real life exposure as
                                        they tour the Justice Center, University of Cincinnati Hospital, and Cincinnati State University. Through
                                        the tours and mentorship, they begin to envision a positive future which could include attending college,
                                        as well as a career as a police officer, a firefighter, or as a medical professional. “We are changing the
                                        narrative. Paths can be altered, and youth see new opportunities for where life can take them if they
                                        are respectful and work hard. The only place where success comes before hard work is in the
                                        dictionary.”
                                    </p>
                                    <p className="mt-6">
                                        In addition to Danita’s law enforcement career, she serves the community as a ‘Big Sister’ through the
                                        Big Brothers and Big Sisters of Greater Cincinnati. “When I pick up my ‘little sister’ who is 8 years old, I
                                        see her face light up, because she is genuinely excited to see me. She knows we are going to spend
                                        meaningful time together while having fun. I derive joy from making a difference in her life.” Since
                                        early 2023, Danita has also been volunteering at National Underground Railroad Freedom Center.
                                        Captain Danita Pettis is a role model for citizens of all ages throughout the community. Her
                                        commanding presence is rooted in leadership, passion, and diplomacy. “I liked to be the spark which
                                        sets things in motion and to be a leader among leaders. Whenever I receive a ‘no’--I view that response
                                        as a word that is waiting to be converted into a ‘yes.’”
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex items-center p-7">
                        <div class="flex-1 border-t-2 border-gray-200"></div>
                    </div>

                    <div id="4" className="mx-auto grid max-w-3xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <div
                            className="absolute right-1/2 left-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
                            aria-hidden="true"
                        >
                            <div
                                className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#0daddc] to-[#f3742a] opacity-60"
                                style={{
                                    clipPath:
                                        'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
                                }}
                            />
                        </div>
                        <div className="">
                            <div className="mx-auto relative overflow-hidden rounded-3xl bg-teal-50 px-6 pb-9 pt-9 shadow-2xl sm:px-12 max-w-md lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
                                <img
                                    className="h-full w-full rounded-2xl object-scale-down bg-teal-50"
                                    src="./assets/janny.jpg"
                                    alt=""
                                />

                            </div>
                        </div>
                        <div>
                            <div className="text-base leading-7 text-gray-700 lg:max-w-2xl sm:pl-6">
                                <h1 className="mt-2 max-w-2xl text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                    Introducing Janny Tolbert, Board Treasurer of SuperSeeds
                                </h1>
                                <div className="max-w-2xl">
                                    <p className="mt-6 sm:mx-auto">
                                        Janny Tolbert, Board Treasurer at SuperSeeds, is a resilient, smart, empathetic, and humble individual.
                                        Born and raised in Honduras, Janny lived with her grandmother from the time she was one year old until
                                        she left for the United States, at age 16. “My parents left Honduras when I was one, so my entire
                                        upbringing was with my grandmother. She passed away in 2019, and she will always be my source of
                                        inspiration. One of the greatest lessons she taught me was to always stay humble.”
                                    </p>
                                    <p className="mt-6">
                                        After moving to Cincinnati, Ohio, she enrolled at University of Cincinnati and earned her Associate
                                        Degree in Organizational Leadership. She quickly applied her work ethic and knowledge and within 16
                                        months of joining UPS, she was promoted to Supervisor. During 2020, while working at UPS, Janny
                                        became engaged and was married in 2021.
                                    </p>
                                    <p className="mt-6">
                                        Subsequently, in August of 2021, Janny was recruited by Candice Tolbert, her Mother-In-Law, to become
                                        the Payroll Manager at Assist Financial Group, LLC. In addition to managing payroll for 120 plus clients,
                                        Janny performs bookkeeping and tax preparation. “I have always loved math and I am a quick learner,
                                        so working with numbers comes very natural to me. I also love the one-on-one relationships with the
                                        clients and the opportunity to learn about all aspects of their business.”
                                    </p>
                                    <p className="mt-6">
                                        As Janny discovered that Candice is also the Executive Director of SuperSeeds, a nonprofit serving youth
                                        between the ages of 13 and 18, she expressed an interest in serving as Board Treasurer. Janny is
                                        responsible for the payables, receivables, and profit &amp; loss statements. “Candice, who I call ‘Mom’, is
                                        warm, kind, and inspirational. Mom overcame a tremendous amount of doubt and criticism from others
                                        when she launched her nonprofit. I admire her strength and the effect she has on others. It is amazing
                                        to see the changes in behavior, respect and confidence in the youth who attend her 2-Day
                                        Transformation Camps!”
                                    </p>
                                    <p className="mt-6">
                                        Janny Tolbert is proud of her Hispanic heritage and culture and speaks Spanish and English and is
                                        learning Portuguese. Next on her list is to learn French and Italian. She wants to be fluent in 5
                                        languages. Janny honors her grandmother by staying grounded and rooted in family, as this is the most
                                        important priority in her life. She is grateful for and excited about her future. In addition to her full-time
                                        position, she is building an investment portfolio through acquiring rental properties. When the timing is
                                        right, Janny and her husband will start a family. Due to Janny’s level of drive and determination, there is
                                        no doubt that she will achieve whatever she sets her mind to.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex items-center p-7">
                        <div class="flex-1 border-t-2 border-gray-200"></div>
                    </div>

                    <div id="5" className="mx-auto grid max-w-3xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <div
                            className="absolute right-1/2 left-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
                            aria-hidden="true"
                        >
                            <div
                                className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#0daddc] to-[#f3742a] opacity-60"
                                style={{
                                    clipPath:
                                        'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
                                }}
                            />
                        </div>
                        <div className="">
                            <div className="mx-auto relative overflow-hidden rounded-3xl bg-teal-50 px-6 pb-9 pt-9 shadow-2xl sm:px-12 max-w-md lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
                                <img
                                    className="h-full w-full rounded-2xl object-scale-down bg-teal-50"
                                    src="./assets/MelissaJames.jpg"
                                    alt=""
                                />

                            </div>
                        </div>
                        <div>
                            <div className="text-base leading-7 text-gray-700 lg:max-w-2xl sm:pl-6">
                                <h1 className="mt-2 max-w-2xl text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                    Introducing Melissa James, Board Secretary of SuperSeeds
                                </h1>
                                <div className="max-w-2xl">
                                    <p className="mt-6 sm:mx-auto">
                                        Melissa James, Board Secretary, exemplifies a Woman of God who is articulate, poised, and confident
                                        yet transparent, empathetic, and cut from a ‘servants’ cloth. Born and raised in Cincinnati, Ohio,
                                        Melissa grew up in a family rooted in love. “I grew up with four siblings and my parents pushed us to
                                        achieve excellence. My Father is a Pastor and my Mother worked for years in the Insurance Industry.
                                        They instilled my work ethic and my values—to always be respectful, impactful, and humble.”
                                    </p>
                                    <p className="mt-6">
                                        Melissa earned her Bachelor’s in Business Administration from Kentucky State University. Straight out
                                        of college she immediately accepted a position at The Kroger Company and worked in the field for two
                                        years as a manager overseeing 100+ employees. Her ability to effectively listen and communicate with
                                        customers, employees, and union representatives, toughened her skin, and laid the foundation for her
                                        inclusive and equitable demeanor. As Melissa’s leadership skills continued to blossom during the next
                                        seventeen years at Cincinnati Financial, she progressed from Underwriter through five promotions to
                                        Care Center Manager—Personal Lines Division. At each level, Melissa obtained relevant insurance
                                        designations and licenses. “I love to learn, and I love to cultivate leaders. Pouring into others, guiding
                                        others, mentoring others, and serving others are what I was designed to do!”
                                    </p>
                                    <p className="mt-6">
                                        For over 20 years, Melissa and her husband, Jeremiah, have been family friends with Candice Tolbert,
                                        Executive Director of SuperSeeds. In early 2023, Candice and Melissa had a conversation regarding the
                                        vision and mission of SuperSeeds. Once Melissa experienced a tour with the at-risk-youth, who are
                                        served through SuperSeeds, she was compelled to help and extend her servant’s heart. She joined the
                                        Board in May 2023. “I have lived in Cincinnati my entire life and it was both eye-opening and heart-
                                        breaking to learn about the challenges that today’s youth are facing. I immediately wanted to add value
                                        and to impact our youth. It is one thing to talk about change and it is another thing to do something
                                        about it!”
                                    </p>
                                    <p className="mt-6">
                                        Through the 2-Day Transformation Camps, lives are reformed, restored and redirected. The youth know
                                        they are in a safe space; therefore, they open up and share what is happening in their homes and in
                                        their schools. The need is vast, and youth are seeking to be heard and to know that their voice and their
                                        existence matter. “Often, we have youth who want to return to the Camps and share their experiences
                                        with the next group. They want to become future leaders.”
                                    </p>
                                    <p className="mt-6">
                                        Melissa James is excited to contribute her ideas and leadership to the Board, and passionate about
                                        impacting youth and parents via her position as Program Coordinator. “My heart is for all people, and I
                                        know how to motivate, encourage, and empower others. Now is the time for me to walk in my
                                        authenticity through pouring out hope and serving others. My favorite scripture is Jeremiah 29:11. “For
                                        I know the plans I have for you, declares the Lord, plans to prosper you and not to harm you, plans to
                                        give you a hope and a future.”
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex items-center p-7">
                        <div class="flex-1 border-t-2 border-gray-200"></div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    );

}