import Footer from "./Footer";
import NavBar from "./NavBar";
import NavToHome from "./NavToHome";

export default function MeetTheFounder() {

    return (

        <div>
            {/* Header */}
            <NavBar />


            <div className=" py-24 sm:py-32">
                
                <div
                    className="absolute right-1/2 left-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
                    aria-hidden="true"
                >
                    <div
                        className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#70f900] to-[#1ec5db] opacity-60"
                        style={{
                            clipPath:
                                'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
                        }}
                    />
                </div>
                <div className="mx-auto px-6">
                    <div className="mx-auto grid max-w-3xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <div className="">
                            <div className="mx-auto relative overflow-hidden rounded-3xl bg-teal-50 px-6 pb-9 pt-9 shadow-2xl sm:px-12 max-w-md lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
                                <img
                                    className="h-full w-full rounded-2xl object-scale-down bg-teal-50"
                                    src="./assets/CandiceTolbert.jpg"
                                    alt=""
                                />

                            </div>
                        </div>
                        <div>
                            <div className="text-base leading-7 text-gray-700 lg:max-w-2xl sm:pl-6">
                                <h1 className="mt-2 max-w-2xl text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                    Introducing Candice Tolbert, Executive Director of SuperSeeds
                                </h1>
                                <div className="max-w-2xl">
                                    <p className="mt-6 sm:mx-auto">
                                        Upon meeting Candice Tolbert, Executive Director of SuperSeeds, you will immediately sense her
                                        genuine care and compassion for others, along with her personal confidence, drive, and determination.
                                        Born and raised in Cincinnati, Ohio, Candice earned her Associate Degree in Business Management from
                                        Cincinnati State Technical and Community College. Not sure of her exact future, she was certain that
                                        she would impact and serve others. “I am a servant in everything I do.” After working in Human
                                        Resources at Duke Energy for 7 years, Candice started her own business, Assist Financial Group, LLC, in
                                        September of 2003. As the Founder and President, she is proud to offer her clients a full suite of Tax
                                        Preparation, Bookkeeping and Payroll Services. Over the past 2 decades, she has assisted individuals,
                                        small business owners and non-profit entities with all aspects of taxes and financials. Her credential as a
                                        Licensed Tax Preparer includes an AFSP Designation, which she earned and has maintained since 2014.
                                    </p>
                                    <p className="mt-6">
                                        In the Fall of 2012, Candice's son was suspended from a private school for playing a 'staring game' with a
                                        fellow student. As a result of the female student's accusation, her son was immediately suspended for
                                        one day. On January 25, 2013, Candice launched SuperSeeds, a non-profit dedicated to serving youth
                                        between the ages of 13-17 who have been suspended or arrested as they understand that these
                                        circumstances feed the pipeline to prison. “Youth need restorative programs that transform their
                                        thinking versus punitive programs. How does a dismissal from the classroom transform thinking or
                                        modify their behavior?” Candice believes in collaboration and offers a 2-Day Transformation Camp,
                                        which serves about 240 youth per year, which unites the village of individuals, business owners, non-
                                        profit organizations, and corporations with a vested community interest in disrupting the pipeline to
                                        prison. 95% of the youth in the Camp are referred to SuperSeeds from Juvenile Court and participating
                                        schools, 3% are referred by parents, and 2% are referred by other youth who have completed the 2-Day
                                        Camp.
                                    </p>
                                    <p className="mt-6">
                                        “Youth need to understand the real-life consequences of their actions from a restorative and
                                        educational approach. During the 2-days, we transport them by bus to the Hamilton County Justice
                                        Center, UC Medical Center and to a local college or university, such as Cincinnati State Technical &amp;
                                        Community College or the University of Cincinnati, so they can understand the path they are on and the
                                        critical role these entities play in our society.” The transformations are miraculous. In the matter of 2-
                                        days, there is change in attitude, demeanor, and behavior. The long-term results are academic
                                        improvement along with an increase in communication with their parents. Such transformations fuel,
                                        motivate, and inspire Candice. She knows that lives are being positively impacted and progress is being
                                        made. Candice genuinely cares about our youth. She is in their corner and provides them with
                                        confidence and empowerment. In turn, they work to become a better version of themselves one day at
                                        a time, knowing that someone genuinely cares about them.
                                    </p>
                                    <p className="mt-6">
                                        Candice Tolbert is a visionary and loves to educate and counsel others. As a clear and concise
                                        communicator, she quickly earns the respect from those she is dedicated to serving. Combine these
                                        gifts with her strong business acumen and here lies her formula for continued success. Candice is
                                        known for saying, “Know More, (and then) Be Better!”
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    );

}