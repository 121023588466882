import { useState, useEffect, useRef, Fragment } from 'react'
import YouTube from 'react-youtube';
import Footer from './Footer';
import About from './About';
import Contacts from './Contacts'
import NavBar from './NavBar'

const navigation = [
  { name: 'Home', href: '#home' },
  { name: 'Mission', href: '#mission' },
  { name: 'Donate', href: '#donate' },
  { name: 'About Us', href: '#team' },
  { name: 'Contact', href: '#contact' },
]

const navSecondRow = [
  { name: 'Meet the Founder', href: '/founder' },
  { name: 'Board of Directors', href: '/board' },
  { name: 'What We Do', href: '/whatwedo' },
  { name: 'Ways To Help', href: '/waystohelp' },
  { name: 'Partners & Schools', href: '/partners' },
  { name: 'News & Media', href: '/media' },
  { name: 'FAQs', href: '/faqs' },


]
const stats = [
  { label: 'Meals served by Hamilton County jail system every year', value: '1.6 million' },
  { label: 'Total Out-Of-School Suspensions in 2021-2022', value: '194,496' },
  { label: 'Total Out-Of-School Suspensions in 2022-2023', value: '210,019' },
  { label: 'Potential Taxpayer Savings. See breakdown in Our Data.', value: '$4.2 millon' },
]

const opts = {
  height: '450',
  width: '720',
  playerVars: {
    autoplay: 0,
  },
};
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Home() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const donateRef = useRef(null);

  useEffect(() => {
    //Flipcause cause id
    let cause_id = 'MTgzOTI1'
    // This code will be executed after the component has been rendered
    document.getElementById("fc-embed-loader").style.display = "block";
    document.getElementById("fc-embed-main-box").style.display = "block";
    document.getElementById("fc-embed-main-box").style.webkitAnimation = "fadesin 2s";
    document.getElementById("fc-embed-main-box").style.animation = "fadesin 2s";
    document.getElementById("fc-embed-main-box").style.mozAnimation = "fadesin 2s";
    document.getElementById("fc-embed-frame").style.display = "block";
    document.getElementById("fc-embed-frame").style.webkitAnimation = "fadesin 2s";
    document.getElementById("fc-embed-frame").style.animation = "fadesin 2s";
    document.getElementById("fc-embed-frame").style.mozAnimation = "fadesin 2s";
    document.getElementById("fc-embed-frame").src = "https://www.flipcause.com/widget/" + cause_id;

    // Get the hash value from the URL (e.g., "#donate")
    const hash = window.location.hash;
    if (hash === "#donate") {
      // Scroll to the donate section
      donateRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);

  return (
    <div className="bg-white">
      {/* Header */}
      <NavBar />
      <main className="isolate">
        {/* Hero section */}
        <div id="home" className="relative isolate -z-10 scroll-mt-24">
          <svg
            className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect width="100%" height="100%" strokeWidth={0} fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
          </svg>
          <div
            className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
            aria-hidden="true"
          >
            <div
              className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#0daddc] to-[#f3742a] opacity-60"
              style={{
                clipPath:
                  'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
              }}
            />
          </div>
          <div className="overflow-hidden">
            <div className="mx-auto max-w-7xl px-6 pb-32 pt-5 lg:px-8">
              <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                  <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                    Transforming Youth Behavior
                  </h1>
                  <br />
                  <p className="text-lg leading-8 text-gray-900">A 501(c)3. Planting seeds of Awareness Hope & Courage.</p>
                  <br />
                  <br />

                  <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Vision</h2>
                  <br />
                  <p className="relative text-justify mt-0 text-lg leading-8 text-gray-900 max-w-md">
                    Our Vision is to serve as a catalyst for prevention and restoration empowering youth to become positive contributing members of our community disrupting the pipeline to prison
                  </p>
                </div>
                <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                  <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                    <div className="relative">
                      <img
                        src="./assets/SS-Pics/jailVisit.jpg"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg object-right"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                    <div className="relative">
                      <img
                        src="./assets/SS-Pics/judge.jpg"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-contain shadow-lg object-right"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <img
                        src="./assets/SS-Pics/policeTalk.jpg"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                    <div className="relative">
                      <img
                        src="./assets/SS-Pics/firstTour.jpg"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <img
                        src="./assets/SS-Pics/ssKids.jpg"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Content section */}
        <div id="mission" className="mx-auto -mt-12 max-w-7xl px-6 sm:mt-0 lg:px-8 xl:-mt-8 scroll-mt-24">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Mission</h2>
            <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
              <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
                <p className="text-lg leading-8 text-gray-900">
                  Through planting seeds of Awareness, Hope and Courage within youth to impact Thought, Behavior & Vision.
                </p>


                <h2 className="mt-10 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Why</h2>
                <br />
                <p className="text-justify text-lg leading-8 text-gray-900">
                  Each year, Ohio schools issued 210,019 out-of-school suspensions in the 2022-2023 School
                  Year. The highest categories of reasons why they are not permitted to be seated in the
                  classroom can vary. However, the highest category of out-of-school suspensions is
                  “Disobedient/Disruptive Behavior” were 94,245 (almost half), which is highly subjective. Data
                  shows that subjective determinations when disproportionately applied may be
                  susceptible to the influence of implicit bias. The harmful effect of removing youth from the
                  academic process is that it feeds into the school-to-prison pipeline. Instances, where a child is
                  removed from school for fighting in handcuffs, have profound impacts on the youth and our
                  community at large.
                  <br />
                  Research has often found that racial disparities exist when it comes to which students face
                  harsher and more frequent disciplinary actions in schools. Black and Latino students are
                  suspended and expelled from school at disproportionately higher rates than their white
                  counterparts, for instance.
                  <br />
                  A study <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href='https://journals.sagepub.com/eprint/XK6UME6UVGBVFK6AWC2P/full'>published in December 2022 </a>
                  found bias plays a major contributing factor in racial/ethnic
                  disparities in school discipline, in two interconnected ways: individual teacher biases perceiving
                  the misbehavior of students of color as more blameworthy when compared to that of white
                  peers; and districts with a majority of students of color as more likely to have a culture of control
                  and a punitive approach to discipline when compared to predominantly white schools.
                </p>

                <div className="mt-10 max-w-xl text-base leading-7 text-gray-700">
                  <p>
                    <a alt="2021-2023 Discipline Data" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="./assets/files/2021-2023 Discipline Data.pdf" target="_blank" >
                      <strong>Facts &amp; Figures of Out-of-School Data</strong>
                    </a>
                  </p>
                </div>

                <p className="mt-10 text-justify text-lg leading-8 text-gray-900">
                  <strong>Facts & Figures (published by Hamilton County Justice Center):</strong>
                  <br />
                  The Hamilton County Justice Center holds an average 1,245 prisoners daily over the past 3
                  years and an estimated 55,000 annually, giving it the dubious distinction of being one of the 25
                  largest jails in the U.S.
                </p>

              </div>
              <div className="lg:flex lg:flex-auto lg:justify-center">
                <dl className="w-64 space-y-8 xl:w-80">
                  {stats.map((stat) => (
                    <div key={stat.label} className="flex flex-col-reverse gap-y-4">
                      <dt className="text-base leading-7 text-gray-600">{stat.label}</dt>
                      <dd className="text-5xl font-semibold tracking-tight text-gray-900">{stat.value}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </div>

        {/* Video section */}
        <div className="mx-auto mt-12 max-w-7xl px-6 sm:mt-10 lg:px-8 xl:mt-12">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-4xl">Video – Our Story</h2>
            <video controls className="w-full" >
              <source src="./assets/SuperSeedsLockland.mp4" type="video/mp4" />
              Your browser does not support viewing this video.
            </video>
          </div>
        </div>
        {/* Donation secion */}
        <div ref={donateRef} id="donate" className="mx-auto mt-14 max-w-7xl px-6 sm:mt-20 lg:px-8 scroll-mt-24">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Donate to SuperSeeds</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Help our organization by donating today! All donations directly impact our organization and help us further our mission.
            </p>
          </div>
          {/* Flipcause donation iframe */}
          <div id="fc-direct-embed-loader-out" className="block">
            <div id="fc-embed-loader" class="fc-direct-embed-loader" className=" block top-64" >
            </div>
          </div>
          <div id="fc-embed-main-box" class="fc-embed-main-box" className="block mx-auto ">
            <iframe id="fc-embed-frame" className=" h-[800px] w-full border-solid m-auto overflow-hidden" src="" ></iframe>
          </div>
        </div>

        {/* Image section */}
        <div className="mt-8 sm:mt-16 xl:mx-auto xl:max-w-7xl xl:px-8">
          <img
            //src="./assets/MainImage_950x460.jpg"
            src="./assets/SS-Pics/firstTour.jpg"

            alt=""
            className="aspect-[5/2] w-full object-cover xl:rounded-3xl"
          />
        </div>

        {/* Team section */}
        <div id="team" className=" pt-24 md:pt-24 lg:pt-32">
          {/* Bg gradient */}
          <div
            className="absolute right-1/2 left-0 -z-10 ml-14 transform-gpu overflow-hidden blur-3xl lg:ml-14 xl:ml-24"
            aria-hidden="true"
          >
            <div
              className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#bed8f5] to-[#beffc8] opacity-60"
              style={{
                clipPath:
                  'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
              }}
            />
          </div>
          <About />
        </div>
      </main>
      {/* Contacts */}
      <Contacts />
      {/* Footer */}
      <Footer />

    </div>
  )
}
