import Footer from "./Footer";
import NavBar from "./NavBar";
import NavToHome from "./NavToHome";
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

export default function Faqs() {
    const faqs = [
        {
            question: "Is this a scared straight program?",
            answer:
                "No, we are not a scared straight but think straight program. We are a restorative program that focuses on building the youth up and not tearing them down.",
        },
        {
            question: "Can parents attend the program?",
            answer:
                "No.  We don't allow parents to attend the program, because we want the youth to experience the tour independently allowing them the freedom to choose for themselves.",
        },
        {
            question: "Do you provide transportation?",
            answer:
                "Yes, we will coordinate pick up and drop off for the youth if they can't get to the pickup/drop off site.",
        },
        {
            question: "How long is the program?",
            answer:
                "The Transformation Camp is a 2-Day Camp held on Tuesdays and Wednesdays from 7:30am - 3pm both days. Youth do not stay overnight.",
        },
        {
            question: "Can the youth bring cell phones?",
            answer:
                "It is recommended to NOT bring cell phones as they are not allowed to be on them during the program. If they bring then, they are placed in a bag and held until the end of the day.",
        },
        {
            question: "What are the ages for the youth you serve?",
            answer:
                "Ages 13-17.",
        },
        {
            question: "Can my child bring a friend?",
            answer:
                "YES! We encourage them to bring a friend to help build support moving forward in making different choices.",
        },
        {
            question: "How do I get my child enrolled into the SuperSeeds Program?",
            answer:
                "Please call the registration number (513-549-1516) and we will email you a link to DocuSign the registration forms. Please click this link to register your child for the Transformation Camp. ONce your paperwork has been completed, you will be contacted to confirm registration, tour date and times.",
        },
        {
            question: "Can my child wear a hat or hoodie?",
            answer:
                "No hats or hoodies are allowed.",
        },
        {
            question: "Can my child return to help SuperSeeds?",
            answer:
                "Yes! We often have youth return to do the program again because they will bring a friend, or they will come to be a chaperone for other youth allowing them to share their story of change.",
        },
        {
            question: "How can my school get involved in this program?",
            answer:
                "SuperSeeds will send a proposal for services based on their needs. SuperSeeds provides a program for the students and can conduct restorative practice training to school administrators.",
        },
        {
            question: "Why do you need their t-shirt size?",
            answer:
                "All SuperSeeds participants must wear their t-shirt on the first day of the tour. This is required for entering the tour sites.",
        },
        {
            question: "My child speaks Spanish; do you have an interpreter?",
            answer:
                "Yes! We have a staff member that can be with them to interpret.",
        },
        {
            question: "Do they stay overnight for the 2-Day Program?",
            answer:
                "No. They will go home at the end of each day.",
        },
        {
            question: "If they miss a day due to illness, can they make up the day?",
            answer:
                "Yes, they will be automatically registered for the next tour to make up for the day that was missed.",
        },
        // More questions...
    ]

    const faqs_section2 = [
        {
            question: "Is this a scared straight program?",
            answer:
                "No, we are not a scared straight but think straight program. We are a restorative program that focuses on building the youth up and not tearing them down.",
        },
        {
            question: "How many youths do you serve each month?",
            answer:
                "We serve at least 20 youths per month. The minimum requirement for each camp is 10 youth.",
        },
        {
            question: "What is your biggest expense for the Transformation Camp?",
            answer:
                "Meals - Each Youth receives lunch on Day 1 and Breakfast, Lunch and Snacks on Day 2.",
        },
        {
            question: "What does each participant receive during the camp?",
            answer:
                "A T-Shirt, Drawstring bag, Notepad for notes and a pen.",
        },
        {
            question: "May I attend a program to observe?",
            answer:
                "Yes! We allow up to 2 guests per tour to observe and help with the tours.",
        },
        {
            question: "Do you have audited financials?",
            answer:
                "Yes, we have audited financials.",
        },
        {
            question: "In what ways do you accept payments?",
            answer:
                "You can donate quickly and securely by going to https://superseedscincy.org/#donate . You have the option to give one time or set up a monthly contribution. You can also send via Zelle (SuperSeedsLLC@gmail.com) or mail a check to: SuperSeeds, 10142 Springfield Pike, Woodlawn, OH 45215."
        },
        {
            question: "Is my donation tax-deductible?",
            answer:
                "Yes, we are a certified tax-exempt organization. Please donate at https://superseedscincy.org/#donate ",
        },
        {
            question: "In what other ways can I get involved?",
            answer:
                "We are looking for volunteers, information technology support, and guest speakers.",
        },
        {
            question: "Are you in need of board members?",
            answer:
                "Yes, we are still looking for those individuals who would like to serve on our board to help further our mission.",
        },
    ]


    return (
        <div>
            {/* Header */}
            <NavBar />
            <div className="bg-white">
                <div className="mx-auto max-w-7xl px-6 py-24 sm:py-12 lg:px-8 lg:py-12">
                    {/* FAQs section1 */}
                    <div className="mx-auto max-w-4xl">
                        <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions (For Parents, Youth & Administrators)</h2>
                        <p className="mt-2">
                            Providing Awareness, Hope, and Courage to youth in Cincinnati is our purpose!
                            Below are the 15 most frequently asked questions asked by parents, youth, and
                            administrators. If your questions are not answered through the information
                            below, please email us <a className="text-blue-600 underline hover:text-blue-800" href="mailto:info@SuperSeedsCincy.org">HERE</a>.
                        </p>
                    </div>
                    <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">

                        <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                            {faqs.map((faq) => (
                                <Disclosure as="div" key={faq.question} className="pt-6">
                                    {({ open }) => (
                                        <>
                                            <dt>
                                                <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                                    <span className="text-base font-semibold leading-7">{faq.question}</span>
                                                    <span className="ml-6 flex h-7 items-center">
                                                        {open ? (
                                                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                        ) : (
                                                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                        )}
                                                    </span>
                                                </Disclosure.Button>
                                            </dt>
                                            <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                                <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            ))}
                        </dl>
                    </div>

                    {/* FAQs section2 */}
                    <div className="mx-auto max-w-4xl mt-16">
                        <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions (For Donors, Funders & Supporters)</h2>
                        <p className="mt-2">
                            Below are the 10 most frequently asked questions asked by donors, funders,
                            and supporters. If your questions are not answered through the information
                            below, please email us <a className="text-blue-600 underline hover:text-blue-800" href="mailto:Hope@SuperSeedsCincy.org">HERE</a>.
                        </p>
                    </div>
                    <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">

                        <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                            {faqs_section2.map((faq) => (
                                <Disclosure as="div" key={faq.question} className="pt-6">
                                    {({ open }) => (
                                        <>
                                            <dt>
                                                <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                                    <span className="text-base font-semibold leading-7">{faq.question}</span>
                                                    <span className="ml-6 flex h-7 items-center">
                                                        {open ? (
                                                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                        ) : (
                                                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                        )}
                                                    </span>
                                                </Disclosure.Button>
                                            </dt>
                                            <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                                <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    );

}