import Footer from "./Footer";
import NavBar from "./NavBar";
import NavToHome from "./NavToHome";
import { CheckCircleIcon, ChevronDoubleRightIcon } from '@heroicons/react/20/solid'

export default function OurData() {

    return (
        <div>
            {/* Header */}
            <NavBar />

            <div className="bg-white px-6 py-12 lg:px-8">
                <div className="mx-auto max-w-3xl text-justify text-lg leading-8 text-gray-900">
                    <p className="text-base font-semibold leading-7 text-green-900">Our Data</p>
                    <img src="./assets/SSData.png" className="w-full"/>
                    <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Courts</h1>
                    <p className="mt-6">
                        SuperSeeds referrals from Hamilton County Justice Center from 2019 - 2022 of youth at the entry point of the system
                        (meaning they had been arrested for the 1st or 2nd time) who cases were sent to Diversion.  Diversion would refer parents
                        to bring their child to the SuperSeeds Program.
                    </p>
                    <div className="mt-10 max-w-2xl">
                        <p>
                            The camp focuses on providing youth with:
                        </p>
                        <ul role="list" className="mt-8 max-w-xl space-y-8 text-black-600">
                            <li className="flex gap-x-3">
                                <ChevronDoubleRightIcon className="mt-1 h-5 w-5 flex-none text-black-900" aria-hidden="true" />
                                <span>
                                    From 2019 - 2021, 72% of the youth that attended the Transformation Camp post 1 and 2 years after,  <strong className="font-semibold text-gray-900">did not return </strong>
                                    to the courts with additional cases.
                                </span>
                            </li>
                            <li className="flex gap-x-3">
                                <ChevronDoubleRightIcon className="mt-1 h-5 w-5 flex-none text-black-900" aria-hidden="true" />
                                <span>
                                    SuperSeeds has the highest follow-through rate of ensuring that youth referred, get registered and attend the camp.
                                </span>
                            </li>

                        </ul>

                        <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Taxpayer Savings</h2>
                        <p className="mt-6">
                            The statistics for 2023 show that there were 243 youths served from courts. The cost to serve a youth per day is $507. With an average stay of 34 days, the total cost to tax payers comes out to $4.2 million!
                        </p>


                        <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Schools</h2>
                        <p className="mt-6">
                            SuperSeeds began working with the Lockland School District in 2016 after learning they had 1,500 referrals from the classroom to
                            the Principal's Office.  After 2 years of working with youth, parents and school administrators, in 2018 the year ended with 100 referrals.
                        </p>
                        <ul role="list" >
                            <li >* 2016 - 1,500 Discipline Referrals</li>
                            <li >* 2018 - 100 Discipline Referrals (2 Year Reduction)</li>
                        </ul>
                        <h4 className="mt-16 text-xl font-bold tracking-tight text-gray-900"> What We Learned From Lockland?</h4>
                        <p className="mt-6">

                            <ol role="list" >
                                <li ><strong>1.</strong> The more they sent, the more they could see that the youth returned and began the peer-to-peer influence.
                                    Teachers didn't have to interfere in some cases, because the students would intervene based on their camp learning experience.
                                </li>
                                <li ><strong>2.</strong> Teachers had a tool - SuperSeeds.  They could be Pro-active versus Reactive. Instead of waiting until the behavior demanded
                                    a referral to the Principal's office, they could refer youth to attend when they saw behaviors trending downward.
                                </li>

                                <li ><strong>3.</strong> Parents were invited to be engaged in the process.  They held a community forum inviting parents, law enforcement, key community
                                     leaders and school administrators to come together to discuss the need to work with administrators to address behaviors before a suspension or arrest occurs.
                                </li>
                            </ol>
                        </p>
                        <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900"> Our Grants &  Awards:</h2>
                        <ul role="list" >
                            <li >&#8226; United Way - Black Empowerment Grant</li>
                            <li >&#8226; Else Heisel Sule Foundation</li>
                            <li >&#8226; City of Cincinnati Human Services Fund</li>
                            <li >&#8226; Hamilton County Sheriff's Office Grant</li>
                            <li >&#8226; Cincinnati Children's Hospital</li>
                            <li >&#8226; <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://cincinnatusassoc.org/awards/spencer-awards/">2024 Donald and Marian Spencer “Spirit of America” Award</a></li>
                            <li >&#8226; <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://www.hcso.org/hcso-staff-and-partners-recognized-at-annual-crimestoppers-awards/">2022 Crime Stoppers Award</a></li>
                            <li >&#8226; <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://www.cctst.org/news/community-engagement-fall-speaker-series-2017?rq=Super%20Seeds">2017 CCTST Academic -Community Research Partnership Award</a></li>
                        </ul>

                    </div>
                </div>
            </div>
            <Footer />
        </div>

    );

}